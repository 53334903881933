import { useEffect } from "react";

import { NoFooterLayout } from "../components/Layouts/NoFooterLayout";
import white_arrow from "../assets/images/icons/white-left-arrow.svg";
import { Card, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios, { AxiosError } from "axios";
import { Email, UserInfo } from "../store";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import google_icon from "../assets/images/icons/icons8-google-96.svg";
import email_icon from "../assets/images/icons/email_sign.png";
import { useGSignin, useSendEmail, useSendEmailAdmin } from "../services/Query";
import { useGoogleLogin } from "@react-oauth/google";
import TokenService from "../services/TokenService";
import  ReactLoading  from 'react-loading';
interface tokenType {
  token: string;
}
type LoginValues = {
  email: string;
  password: string;
};

export const Login = () => {
  const navigate = useNavigate();
  // const { mutate: onLogin } = useLogin();
  const { mutate: onSignin,isLoading:isLoading } = useGSignin();
  const [userinfo, setUserinfo] = useRecoilState(UserInfo);
  const { handleSubmit } = useForm<LoginValues>();
  const setEmail = useSetRecoilState(Email);
  const { isLoading: loading, mutate: SendEmail } = useSendEmailAdmin();

  useEffect(() => {
    if (userinfo) navigate("/dashboard");
  }, [userinfo]);
  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const userInfo = await axios.get(
        "https://www.googleapis.com/oauth2/v3/userinfo",
        { headers: { Authorization: `Bearer ${tokenResponse.access_token}` } }
      );
      const vData = userInfo.data;
      const data = {
        email: vData.email,
        firstName: vData.given_name,
        lastName: vData.family_name,
        googleId: vData.sub,
      };
      onSignin(JSON.stringify(data), {
        onSuccess: (res) => {
          const { member, token } = res;
          setUserinfo(member);
          TokenService.updateLocalAccessToken(token);
          if (member.member_Verified) {
            navigate("/dashboard");
          } 
        },
        onError: (err) => {
          const res = err as AxiosError;
          const tokenObj = res.response?.data as tokenType;
          if (tokenObj) {
            const email = data.email;
            SendEmail(email, {
              onSuccess: (res) => {
                if (!res) {
                  setEmail({
                    Email: data.email, 
                  });
                  navigate('/invite-code')
                }
              },
              onError: (error) => {
                console.log({error})
              },
            });
          }
        },
      });
    },
    onError: (errorResponse) => console.log(errorResponse),
  });
  const renderLoader = () => (
    <div className="w-100 text-center py-4"
    style={{
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}>
      <ReactLoading
        type="spinningBubbles"
        className="mx-auto my-auto"
        height={"70px"}
        width={"70px"}
        color="#666666"
      />
    </div>
  );
  return (
    <NoFooterLayout topClass="box_shadow">
      <div className="signup_wrap">
        <button
          className="back_btn"
          onClick={() => {
            navigate("..", { replace: true });
          }}
        >
          <img src={white_arrow} alt="" /> back
        </button>
        <Container className="pt-5">
          <Card className="sign_card mx-auto">
            <Card.Header>Sign in with Google</Card.Header>
            <Card.Body>
              <div className="mt-4">
                <Container>
                  <div className="d-flex justify-content-center mt-5">
                    <button
                      onClick={() => {
                        googleLogin();
                      }}
                      className="d-flex py-3 text-black align-items-center px-3 w_auto justify-content-center"
                    >
                      <img
                        width={30}
                        // style="margin-top:7px; margin-right:8px"
                        alt="Google sign-in"
                        src={google_icon}
                      />
                      <h6 className="mb-0 ms-3">Sign in with Google</h6>
                    </button>
                  </div>
                  {/* <div className="d-flex justify-content-center ">
                    <span>Or</span>
                  </div>
                  <div className="d-flex justify-content-center mt-3 mb-3 ">
                    <button
                      onClick={() => {
                        navigate("/login-email");
                      }}
                      className="d-flex py-3 text-black align-items-center px-3 w_auto justify-content-center"
                    >
                      <img
                        width={28}
                        // style="margin-top:7px; margin-right:8px"
                        alt="Email sign-in"
                        src={email_icon}
                      />
                      <h6 className="mb-0 ms-3">Sign in with email</h6>
                    </button>
                  </div> */}
                </Container>
              </div>
            </Card.Body>
          </Card>
          <>
          {(isLoading || loading) && renderLoader()}
          </>
        </Container>
      </div>
    </NoFooterLayout>
  );
};
