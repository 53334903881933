import { MainLayout } from "../components/Layouts/MainLayout";
import white_arrow from "../assets/images/icons/white-left-arrow.svg";
import { Form, InputGroup } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useGetStandard, useGetUnitInfo } from "../services/Query";
import { convertContentDefaultImageSize } from "../services/Utils";
import { useEffect, useState } from "react";

const ViewUnit = () => {
  let { id: unit_id } = useParams();
  const navigate = useNavigate();
  const [listNoteDocuments, setListDocuments] = useState<any>([])
  const [learningObjectiveLessons, setLearningObjectiveLessons] = useState<any>([])
  const { data: standards } = useGetStandard();
  const { data: unitInfo, isLoading } = useGetUnitInfo(unit_id);

  useEffect(() => {
      if (!!unitInfo?.learningObjects && !!standards) {
        const learnignObjectives = JSON.parse(unitInfo.learningObjects || "[]")
        if (learnignObjectives.length > 0) {
          const frameWorkIds = Array.from(new Set(learnignObjectives.map((it: any) => it.parentId)));
          const standardDocuments = standards.CFDocuments.filter((it: any) => frameWorkIds?.includes(it.identifier));
          const dataDocuments = standardDocuments.map((it: any) => {
            const record = learnignObjectives.filter((record: any) => record.parentId == it.identifier);
            return {
              identifier: it.identifier,
              title: it.creator,
              children: record
            };
          })
          setListDocuments(dataDocuments)
        }
      }

    if (unitInfo?._Lessons && unitInfo?._Lessons?.length > 0) {
      var objectiveArr = unitInfo?._Lessons.map((it: any) => JSON.parse(it.lesson_Standards)).flat(1).filter(it => it != null);;
      setLearningObjectiveLessons(objectiveArr);
    }
  }, [unitInfo?.learningObjects, standards])

  return (
    <MainLayout>
      <div className="top_blue_action_bar  py-2">
        <div className="container">
          <button
            className="back_btn_o"
            onClick={() => {
              navigate(-1);
            }}
          >
            <img src={white_arrow} alt="" /> back
          </button>
        </div>
      </div>
      <div className="edit_box mx-auto p-3 fnt_robo">
        <div className="row gy-3">
          <div className="col-12">
            <Form.Group>
              <Form.Label>
                <b>unit name</b>
              </Form.Label>
              <p>{unitInfo?.name}</p>
            </Form.Group>
          </div>
          <div className="col-12">
            <Form.Group>
              <Form.Label>
                <b>key ideas</b>
              </Form.Label>
              {unitInfo?.keyIdea?.map((it, idx) => (
                <li key={it}>{it}</li>
              ))}
            </Form.Group>
          </div>
          <div className="col-12">
            <Form.Group>
              <Form.Label>
                <b>standards and learning objectives</b>
              </Form.Label>
              {
                !!listNoteDocuments && listNoteDocuments.length > 0 && listNoteDocuments.map((it: any) => (
                  <>
                    <b style={{ marginBottom: "12px", display: "block" }}>
                      <i className="fal fa-clipboard-list-check fa-lg me-1"></i> <span>{it.title}</span>
                    </b>
                    {
                      !!it.children && it.children.length > 0 && it.children.map((item: any) => (
                        <ul>
                          <li>
                            <span style={{ fontWeight: 700 }}>{item.humanCodingScheme}</span> {item.fullStatement}
                            <ul>
                            {
                              !!item.children && item.children.length > 0 && item.children.map((record: any) => (
                                <li key={record.id}>{record.title}</li>
                              ))
                            }
                            {
                              learningObjectiveLessons.length > 0 && learningObjectiveLessons.filter((a: any) => a.identifier == item.identifier &&  item.children.find((t: any) => t.title.includes(a.title)) == null).map((record: any) => (
                                <li key={record.id}>{record.title}</li>
                              ))
                            }
                            </ul>
                          </li>
                        </ul>
                      ))
                    }
                    
                  </>
                ))
              }
            </Form.Group>
          </div>
          <div className="col-12">
            <Form.Label className="fw-bold fnt_robo">
              companion printed title(s)
            </Form.Label>
            <div className="row">
              <div className="col-7">
                <InputGroup className="mb-3">
                  <Form.Control
                    type="text"
                    readOnly
                    value={unitInfo?.companion}
                  />
                </InputGroup>
              </div>
              <div className="col">
                <InputGroup className="mb-3">
                  <InputGroup.Text>pages or page range</InputGroup.Text>
                  <Form.Control type="text" readOnly value={unitInfo?.pages} />
                </InputGroup>
              </div>
            </div>
          </div>
          <div className="col-12">
            <b>unit description</b>
            {
              !!unitInfo?.description && (
                <p className="overflow-x-auto"
                  dangerouslySetInnerHTML={{
                    __html: convertContentDefaultImageSize(
                      unitInfo?.description || ""
                    ),
                  }}
                />
              )
            }
            
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
export default ViewUnit;
