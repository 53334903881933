import { MainLayout } from "../components/Layouts/MainLayout";
import white_arrow from "../assets/images/icons/white-left-arrow.svg";
import { Form, InputGroup, Toast, ToastContainer } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import { useGetCourseInfo, useGetStandard, useUpdateDefaultImage } from "../services/Query";
import { courseDefaultImage } from "../models/defaults";
import { Children, useEffect, useMemo, useRef, useState } from "react";
const backend_baseUrl = process.env.REACT_APP_BACKEND_URL;
const backend_opensaltUrl = process.env.REACT_APP_OPENSALT;
const ViewCourse = () => {
  let { id: course_id } = useParams();
  const navigate = useNavigate();
  const { data: courseInfo } = useGetCourseInfo(course_id);
  const [defaultImage, setDefaultImage] = useState<string>("")
  const [collapse, setCollapse] = useState<boolean>(false)
  const { mutate: updateImageDefault } = useUpdateDefaultImage();
  const [toastShow, setToastShow] = useState(false);
  const { data: standards } = useGetStandard();
  const [itemObjectDocuments, setItemObjectDocuments] = useState<any[]>([]);

  const handChangeImage = (image: string) => {
    setDefaultImage(image);
    let obj = {
      courseId: course_id,
      image: image
    }
    !!course_id && updateImageDefault(JSON.stringify(obj));
    setToastShow(true)
  }

  useEffect(() => {
    !!courseInfo && setDefaultImage(courseInfo?.defaultImage || "")
  }, [courseInfo])


  const listStandards = useMemo(() => {
    if (!!standards && !!itemObjectDocuments && itemObjectDocuments.length > 0) {
      let frameWorks = standards?.CFDocuments.map((item: any) => {
        return {
          identifier: item.identifier,
          creator : item.creator
        }
      });
      return itemObjectDocuments.map((it) => {
        const title = frameWorks?.find((record: any) => record.identifier == it.identifier)?.creator;
        return {
          ...it,
          title: title
        }
      })
    }
   return []
  }, [standards, itemObjectDocuments])

    useEffect(() => {
      if (!!courseInfo?.documentIds && courseInfo.documentIds?.length > 0) {
        const promises = courseInfo?.documentIds.map((item: any) => {
          return fetch(`${backend_baseUrl}/api/Standard/get_standard_url?jsonUrl=${backend_opensaltUrl}/ims/case/v1p0/CFItems/${item}`)
            .then(response=> {
            return response.json()
          });
        });
  
        Promise.all(promises).then(results => {
          var newResults: any = []
          results.forEach((res: any) => {
            if (!res.identifier) return;
            
            var checkExits = newResults.find((it: any) => it.identifier == res.CFDocumentURI?.identifier);
            if (!!checkExits) {
              let humanCodeObj = {
                humanCodingScheme: res.humanCodingScheme,
                fullStatement: res.abbreviatedStatement ?? res.fullStatement
              }
              checkExits.children = [...checkExits.children, humanCodeObj];
            } else {
              let objectNew = {
                identifier: res.CFDocumentURI?.identifier,
                title: "",
                children: [
                  {
                    humanCodingScheme: res.humanCodingScheme,
                    fullStatement: res.abbreviatedStatement ?? res.fullStatement,
                  }
                ]
              }
              newResults.push(objectNew);
            }
          });
          setItemObjectDocuments([...newResults])
        })
      }
    }, [courseInfo])

  return (
    <MainLayout>
      <ToastContainer
          className="p-3 position-sticky"
          position={"middle-center"}
        >
          <Toast show={toastShow} onClose={() => setToastShow(false)} delay={2000} autohide>
            <Toast.Body>saved successfully</Toast.Body>
          </Toast>
        </ToastContainer>
      <div className="top_blue_action_bar py-2">
        <div className="container">
          <button
            className="back_btn_o"
            onClick={() => {
              navigate(-1);
            }}
          >
            <img src={white_arrow} alt="" /> back
          </button>
        </div>
      </div>
      <div className="edit_box mx-auto py-3">
        <div className="row gy-3 fnt_robo">
          <div className="col-12">
            <Form.Group>
              <Form.Label>
                <b>course name</b>
              </Form.Label>
              <h6>{courseInfo?.course_Name}</h6>
            </Form.Group>
          </div>
          <div className="col-12">
            <Form.Group>
              <Form.Label>
                <b>original course creator</b>
              </Form.Label>
              <h6>{courseInfo?.nameOwner}</h6>
            </Form.Group>
          </div>
          {
            !!courseInfo?.nameAdmin &&  courseInfo?.nameAdmin.length > 0 && (
              <div className="col-12">
                <Form.Group>
                  <Form.Label>
                    <b>current course admins</b>
                  </Form.Label>
                  <h6>{courseInfo?.nameAdmin?.join(", ")}</h6>
                </Form.Group>
              </div>
            )
          }
          <div className="col-12">
            <Form.Group>
              <Form.Label>
                <b>standards</b>
              </Form.Label>
              {
                !!listStandards && listStandards.length > 0 && listStandards.map((it: any) => {
                  return (
                    <ul style={{ paddingLeft: "20px" }}>
                        <li>
                          <p className="mb-md-1 mb-lg-2" style={{ fontWeight: 600 }}> <i className="fal fa-clipboard-list-check fa-lg me-1"></i> {it.title}</p>
                          {
                            !!it.children && it.children.length > 0 && it.children.map((record: any) => (
                                <ul>
                                  <li>
                                  <p className="mb-md-1 mb-lg-2"> <i className="fal fa-clipboard-list-check fa-lg me-1"></i> {record.fullStatement}</p>
                                  </li>
                                </ul>
                            ))
                          }
                          
                        </li>
                    </ul>
                  )
                })}
            </Form.Group>
          </div>
          
          {courseInfo?.publisher_icon && (
            <div className="col-12">
              <Form.Group>
                <Form.Label>
                  <b>publisher logo</b>
                </Form.Label>
                <img
                  src={`${backend_baseUrl}/download/${courseInfo?.publisher_icon.file_Path}`}
                  alt=""
                  width={200}
                  className="d-block"
                />
              </Form.Group>
            </div>
          )}
          <div className="col-12">
            <Form.Group>
              <Form.Label>
                <b>subject/domain</b>
              </Form.Label>
              <h6>{courseInfo?.course_Subject}</h6>
            </Form.Group>
          </div>
          {
            !!courseInfo?.companions && courseInfo?.companions.filter((item: any) => !!item.title && !!item.isbn).length > 0 && (
                <div className="col-12">
                  <Form.Label className="fw-bold fnt_robo">
                    companion print title(s)
                  </Form.Label>
                  {courseInfo?.companions?.map((it, idx) => (
                    <div className="row mb-2" key={idx}>
                      <div className="col-7">
                        <InputGroup>
                          <InputGroup.Text>title</InputGroup.Text>
                          <Form.Control type="text" value={it.title} readOnly />
                        </InputGroup>
                      </div>
                      <div className="col">
                        <InputGroup>
                          <InputGroup.Text>ISBN</InputGroup.Text>
                          <Form.Control type="text" value={it.isbn} readOnly />
                        </InputGroup>
                      </div>
                    </div>
                  ))}
                </div>
            )
          }
        
          <div className="col-12">
            <Form.Group>
              <Form.Label>
                <b>target grade level(s)</b>
              </Form.Label>
              <div>{courseInfo?.course_Levels.join(", ")}</div>
            </Form.Group>
          </div>
          {
            !!courseInfo?.course_Description && courseInfo?.course_Description.length > 0 && (
              <div className="col-12">
                <b>course description</b>
                <p
                  dangerouslySetInnerHTML={{
                    __html: courseInfo?.course_Description || "",
                  }}
                ></p>
              </div>
            )
          }
          
          <div className="col-12">
            <Form.Group>
              <Form.Label>
              <b>default activity header image</b>
              </Form.Label>
              <div className="default-image">
                {!!defaultImage && (
                  <div className={`item-default col-3 mb-2`}>
                    <img src={`/images/${defaultImage}`} alt="" style={{width: "100%", borderRadius: "6px"}}/>
                  </div>
                )}
              </div>
              {
                collapse && (
                  <div className="container">
                    <div className="row">
                      {
                        courseDefaultImage.map((item) => (
                          <div className={`item-default col-3 mb-2 ${defaultImage == item ? "active" : ""}`} onClick={() => handChangeImage(item)}>
                              <img src={`/images/${item}`} alt="" style={{width: "100%", borderRadius: "6px"}}/>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                )
              }
              
            </Form.Group>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
export default ViewCourse;
