import React from "react";
import { Navbar } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import edusfere_logo from "../../../assets/images/images/Logo Full - Dark.png";
import acsi_log from "../../../assets/images/icons/bayard_logo.png";
interface Props {
  wrapClass?: string;
  modalLink?: boolean;
}
export const TopHeader = (props: Props) => {
  const navigate = useNavigate();
  const { wrapClass, modalLink } = props;
  return (
    <Navbar bg="white" expand="sm" fixed="top" className={wrapClass}>
      <div className="d-flex justify-content-between w-100 px-3">
        <div className="d-flex log">
          <Link to="/" className="d-flex">
            <img src={edusfere_logo} alt="" height={52} className="my-auto" />
          </Link>
        </div>
        {!modalLink && (
          <div className="buttons d-flex align-items-end">
            <div className="me-2 d-flex"></div>
            <div className="pb-2">
              <button
                className="login"
                onClick={() => {
                  navigate("/login", { replace: true });
                }}
              >
                Log In
              </button>
            </div>
          </div>
        )}
      </div>
    </Navbar>
  );
};
