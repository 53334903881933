import drive_icon from "../assets/images/icons/1298718_drive_google_data_google drive_storage_icon.svg";
import icon_slides from "../assets/images/icons/2993687_brand_brands_google_logo_logos_icon (1).svg";
import icon_docs from "../assets/images/icons/2993697_brand_brands_docs_google_logo_icon.svg";
import icon_excel from "../assets/images/icons/6296676_excel_microsoft_office_office365_icon.png";
import icon_pdf from "../assets/images/icons/4373076_adobe_file_logo_logos_pdf_icon (1).png";
import icon_sheet from "../assets/images/icons/icon_sheet.png";

export const ageLevel = [
  'infants/toddlers (1-3)',
  'very young learners (4-7)',
  'young learners (8-11)',
  'adolescents (12-17)',
  'young adults (18-35)',
  'middle-aged (35-55)',
  'older learners (55+)',
];
export const agesmLevels = [
  'infants/toddlers',
  'very young learners',
  'young learners',
  'adolescents',
  'young adults',
  'middle-aged',
  'older learners',
];
export const educationLevelArry = [
  'High School',
  'Tesol Certificate',
  "Bachelor's Degree",
  "Master's Degree",
  'Phd or Doctorate',
];
export const classSizeArry = [
  'one-on-one',
  'small (2-10)',
  'average (11-35)',
  'large (36-60)',
  'very large (61+)',
];
export const typeSchoolArry = [
  "Public School",
  "Private School",
  "International School",
  "College/University",
  "Homeschool",
  "Microschool",
  "Other"
];
export const energyLevelArry = ['Low', 'Medium', 'High'];

export const experLevel = [
  'none',
  '1-3',
  '4-6',
  '7-10',
  '11-15',
  '16-20',
  '20+',
];
export const proficiencyLevel = [
  'A1 (Low Beginner)',
  'A2 (High beginner)',
  'B1 (Low Intermediate)',
  'B2 (High Intermediate)',
  'C1 (Low advanced)',
  'C2 (High advanced)',
];
export const proficiencyLevels = [
  { key: 'A1', value: 'A1 (Low Beginner)' },
  { key: 'A2', value: 'A2 (High beginner)' },
  { key: 'B1', value: 'B1 (Low Intermediate)' },
  { key: 'B2', value: 'B2 (High Intermediate)' },
  { key: 'C1', value: 'C1 (Low advanced)' },
  { key: 'C2', value: 'C2 (High advanced)' },
];
export const LangFocus = ['vocabulary', 'grammar', 'functions'];
export const Skills = ['speaking', 'listening', 'reading', 'writing'];
export const lessonStages = [
  'warm-ups / lead ins',
  'introduction',
  'guided practice',
  'independent practice',
  'assessment',
  'homework',
  'review',
];
export const ideaKinds: string[] = [
  'whole class instruction',
  'students moving around the classroom',
  'individual work',
  'multimedia (video or audio)',
  'pair work',
  'group work',
];
export const MessageString = {
  required: 'this field is required',
  discardText: 'are you sure you want to leave and discard?',
  confirmVerify: 'it works only for verified contributors.',
  draftText: 'are you sure you want to save this draft?',
  adaptText: 'are you sure you want to adapt this activity?',
  adaptWait: "we're getting ready for you...just a moment...",
  lessonEmptyObject: 'please add at least one lesson objective.',
  lessonEmptyVocabulary: 'a finished lesson needs at least one vocabulary.',
  lessonEmptySecton: 'a finished lesson needs at least one activity.',
  sectionEmptyObject: 'select at least one lesson objective for this activity.',
  existUser:
    'this email is already in use. please try it again using another email.',
  selectClassTxt: 'for which class are you doing this adaptation?',
  deleteActivity: 'are you sure you want to delete this activity?',
  buildLessonMsg: 'would you like to begin a lesson plan with this activity?',
  deleteLesson: 'are you sure you want to delete this lesson?',
  gUserTrue: 'you are using Gmail login.',
  gUserFalse: 'you are not using Gmail login.',
  feedbackMessage: 'are you sure to send this feedback now?',
};
export const SpecStr = {
  split_1: ':,',
  split_common: ',',
  draft: 'Drafts',
  bookmark: 'Bookmarked',
};
export const activityTypeArry: string[] = [
  'communicative task',
  'drill',
  'game',
  'presentation',
  'project',
  'prompt',
  'worksheet',
  'song/music',
];
export const timeArry: string[] = [
  'none',
  '5 min',
  '10 min',
  '15 min',
  '20 min',
  '25 min',
  '30 min',
  '40 min',
  '50 min',
  '60 min',
];
export const timeArryValus: number[] = [0, 5, 10, 15, 20, 25, 30, 40, 50, 60];
export const userLevel = {
  contributor: 'contributor',
  common: 'user',
};
export const Subjects: string[] = [
  'Art',
  'English Language Arts',
  'Mathematics',
  'Music',
  'Science',
  'Social Studies',
  'Religious Education',
  'Technology', 
  'History', 
  'Foreign Language'
];
export const targetLevels: string[] = [
  'Kindergarten',
  'Pre-k',
  '1st',
  '2nd',
  '3rd',
  '4th',
  '5th',
  '6th',
  '7th',
  '8th',
  '9th',
  '10th',
  '11th',
  '12th',
  'Higher Ed / Adult',
];
export const memberLevel : string [] = [
  'owner', 'course admin', 'course member'
]
export const collectionLevel : string [] = [
  'owner', 'collection admin', 'collection member'
]

export const courseDefaultImage = [
  "header-course-1.png",
  "header-course-2.png",
  "header-course-3.png",
  "header-course-4.png",
  "header-course-5.png",
  "header-course-6.png",
  "header-course-7.png",
  "header-course-8.png",
  "header-course-9.jpg",
  "header-course-10.jpg",
  "header-course-11.jpg",
  "header-course-12.jpg",
  "header-course-13.jpg",
  "header-course-14.jpg",
  "header-course-15.jpg",
  "header-course-16.jpg"
]


export const targetSeting: string[] = [
  '1EdTech',
  'Achieve, Inc. (NGSS)',
  'ACT, Inc.',
  'Alabama Test',
  'Arkansas Department of Education',
  'Higher Ed / Adult',
  '1EdTech',
  'Achieve, Inc. (NGSS)',
  'ACT, Inc.',
  'Alabama Test',
  'Arkansas Department of Education',
  'Higher Ed / Adult',
  'Arkansas Department of Education',

];


export const generateString = (length: number) =>  {
    const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;

    for ( let i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
}

export const loopValues = (val: any, parentId?: string) => {
  let q: any = [];
  if (!!val && val.length > 0) {
    val.forEach((elm: any) => {
      if(elm == null) {
        return;
      }
      
      const { children, ...rest } = elm;
      rest.parentId = parentId ? parentId : rest.parentId;
      q = [...q, rest, ...loopValues(children, rest.parentId)];
    });
    return q;
  }
  return []
}

export const getIconFile = (extension: string) => {
  switch (extension) {
    // file sides
    case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
    case "application/vnd.google-apps.presentation":
        return icon_slides;
    // file doc
    case "application/vnd.google-apps.document":
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        return icon_docs;
    // file pdf
    case "application/pdf":
        return icon_pdf;
    // file sheet
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
    case "application/vnd.google-apps.spreadsheet":
        return icon_sheet;
    default:
      return drive_icon;
  }
}