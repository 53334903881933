import { Container, Modal } from "react-bootstrap";
import close from "../../assets/images/icons/red_close.svg";
import CheckboxTree from "react-checkbox-tree";
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
interface Props {
  show: boolean;
  onOk: () => void;
  leftTitle: string;
  rightTitle: string;
  onDiscard: () => void;
  itemCFAssociation: any
  checked: any
  setChecked: any
  expanded: any
  setExpanded: any
  oneValue?: boolean
  titleFramework?: string
}
export const ConfirmModalStandard = (props: Props) => {

  return (
    <Modal
      show={props.show}
      onHide={props.onOk}
      className="dashboard_video standard_modal"
      centered={true}
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>
          add standards
          <img
            src={close}
            alt=""
            onClick={() => {
              props.onOk();
            }}
            className="closeBtn d-none"
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex justify-content-center">
        <Container>
          <b className="title-standard">
            these are the standards you selected at the course level <br/>
            </b> 
          <div className="my-3 tree-item">
            <i className="fal fa-clipboard-list-check fa-lg me-1"></i> <b>{props.titleFramework}</b>
            <CheckboxTree
                nodes={props.itemCFAssociation}
                expanded={props.expanded}
                showExpandAll={true}
                onExpand={(expanded: any) => props.setExpanded(expanded)}
                checked={props.checked}
                onCheck={(checked: any) => props.setChecked(checked)}
                icons={{
                    check: <span className="fas fa-check-square fa-lg" />,
                    uncheck: <span className="far fa-square fa-lg" />,
                    halfCheck: <span className="far fa-square fa-lg" />,
                    expandClose: <span className="far fa-chevron-right fa-lg" />,
                    expandOpen: <span className="far fa-chevron-down fa-lg" />,
                    leaf: <span className="fal fa-clipboard-list-check fa-lg" />,
                    parentOpen: <span className="fal fa-clipboard-list-check fa-lg" />,
                    expandAll: <span className="fal far fa-expand fa-lg" />,
                    collapseAll: <span className="fal fas fa-compress-wide fa-lg" />,
                    parentClose: <span className="fal fa-clipboard-list-check fa-lg" />,
                }}
            />
          </div>

          <div className="d-flex justify-content-evenly pt-3">
            <button
              className="border_warn bg-white rounded_3 py-2 px-4 fs-4"
              onClick={() => props.onOk()}
            >
              {props.leftTitle}
            </button>
            <button
              className="bg_warn rounded_3 py-2 px-4 fs-4"
              onClick={() => props.onDiscard()}
            >
              {props.rightTitle}
            </button>
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
};
